<div class="modal-body">
  <div>
    <div class="card-body">
      <p class="lead justify-content-between d-flex">
        <span>{{title}}</span>
        <button (click)="close()" class="btn-sm btn">
          <i class="pi pi-times"></i>
        </button>
      </p>
      <div class="form-auth-small m-t-20" [formGroup]="dataForm">
        <div class="form-group">
          <p-password [feedback]="false" inputStyleClass="w-100" formControlName="old_password"
            placeholder="Old Password" [style]="{'width':'100%'}" [toggleMask]="true"></p-password>
          <!-- <input type="password" formControlName="old_password" class="form-control round" placeholder="Old Password"> -->
          @if (dataForm?.get('old_password')?.errors?.required) {
          <span class="error">Old Password should not
            empty</span>
          }
        </div>
        <div class="form-group">
          <p-password [feedback]="false" inputStyleClass="w-100" formControlName="password" placeholder="New Password"
            [style]="{'width':'100%'}" [toggleMask]="true"></p-password>
          <!-- <input type="password" formControlName="password" class="form-control round" placeholder="New Password"> -->
          @if (dataForm?.get('password')?.errors?.matching) {
          <span class="error">Password Should be match</span><br>
          }
          @if (dataForm?.get('password')?.errors?.required) {
          <span class="error">Password should not empty</span>
          }
        </div>
        <div class="form-group">
          <p-password [feedback]="false" inputStyleClass="w-100" formControlName="confirm_password"
            placeholder="Confirm Password" [style]="{'width':'100%'}" [toggleMask]="true"></p-password>
          <!-- <input type="password" formControlName="confirm_password" class="form-control round"
            placeholder="Confirm Password"> -->
          @if (dataForm?.get('confirm_password')?.errors?.matching) {
          <span class="error">Confirm Password Should be match</span><br>
          }
          @if (dataForm?.get('confirm_password')?.errors?.required) {
          <span class="error">Confirm Password should not empty</span>
          }
        </div>
        @if (error_info) {
        <span class="error">{{error_info}}</span>
        }
        <!-- <button type="submit" noDoubleClick (click)="changePassword()" [disabled]="loading"
          class="btn btn-primary btn-round btn-block">
          @if (loading) {
          <i class="pi pi-spin pi-spinner"></i>
          }
          {{ loading ? 'Saving...' : 'Change Password'}}
        </button> -->
        <div class="text-center">
          <p-button icon="pi pi-check-circle" label="{{ loading ? 'Saving...' : 'Change Password'}}"
            (click)="changePassword()" [loading]="loading"></p-button>
        </div>
      </div>
      <!-- <div class="separator-linethrough"><span>OR</span></div> -->
    </div>
  </div>
</div>