import { menu } from "../interface/menu-interface";
import { userPermission } from "../interface/user";
import { UrlServices } from "./url-services";

export const MENU_ITEMS: menu[] = [
    {
        title: 'Dashboard',
        icon: 'pi pi-align-justify',
        link: UrlServices.DASHBOARD_ROUTE,
        data: { permission: {} as userPermission },
    },
    {
        title: 'User',
        icon: 'pi pi-user',
        data: { permission: {} as userPermission },
        children: [
            {
                title: 'User',
                icon: 'pi pi-user',
                link: UrlServices.PAGE_URL.USER.LIST.URL,
                data: { permission: UrlServices.PAGE_URL.USER.LIST.permission },
            },
            {
                title: 'Alliance Partner',
                icon: 'pi pi-th-large',
                link: UrlServices.PAGE_URL.USER.ALLIANCE_PARTNER_LIST.URL,
                data: { permission: UrlServices.PAGE_URL.USER.ALLIANCE_PARTNER_LIST.permission },
            },
            {
                title: 'Role',
                icon: 'pi pi-cog',
                link: UrlServices.PAGE_URL.USER.ROLE.URL,
                data: { permission: UrlServices.PAGE_URL.USER.ROLE.permission },
            },
            {
                title: 'General Master',
                icon: 'pi pi-sitemap',
                link: UrlServices.PAGE_URL.MASTER.GENERAL.URL,
                data: { permission: UrlServices.PAGE_URL.MASTER.GENERAL.permission },
            },
        ]
    },
    {
        title: 'Customers',
        icon: 'pi pi-users',
        link: UrlServices.PAGE_URL.CUSTOMER.LIST.URL,
        data: { permission: UrlServices.PAGE_URL.CUSTOMER.LIST.permission },
    },
    {
        title: 'Product',
        icon: 'pi pi-box',
        // link: UrlServices.PAGE_URL.PRODUCT.LIST.URL,
        data: { permission: {} as userPermission },
        children: [
            {
                title: 'Product',
                icon: 'pi pi-box',
                link: UrlServices.PAGE_URL.PRODUCT.LIST.URL,
                data: { permission: UrlServices.PAGE_URL.PRODUCT.LIST.permission },
            },
            {
                title: 'Vendor',
                icon: 'pi pi-briefcase',
                link: UrlServices.PAGE_URL.PRODUCT.VENDOR_LIST.URL,
                data: { permission: UrlServices.PAGE_URL.PRODUCT.VENDOR_LIST.permission },
            },
        ]
    },
    {
        title: 'Gospel Events',
        icon: 'pi pi-slack',
        data: { permission: {} as userPermission },
        children: [
            {
                title: 'Gospel Events',
                icon: 'pi pi-slack',
                link: UrlServices.PAGE_URL.EVENTS.LIST.URL,
                data: { permission: UrlServices.PAGE_URL.EVENTS.LIST.permission },
            },
            {
                title: 'Distribution Center',
                icon: 'pi pi-map-marker',
                link: UrlServices.PAGE_URL.EVENTS.LOCATION_LIST.URL,
                data: { permission: UrlServices.PAGE_URL.EVENTS.LOCATION_LIST.permission },
            },
        ]
    },
    // {
    //     title: 'Payments',
    //     icon: 'pi pi-wallet',
    //     link: UrlServices.DASHBOARD_ROUTE,
    //     data: { permission: {} as userPermission },
    // },
    // {
    //     title: 'Distribution Center',
    //     icon: 'pi pi-th-large',
    //     link: UrlServices.PAGE_URL.DISTRIBUTION_CENTER.LIST.URL,
    //     data: { permission: {} as userPermission },
    // },
    {
        title: 'Order',
        icon: 'pi pi-shopping-cart',
        // link: UrlServices.PAGE_URL.ORDER.LIST.URL,
        data: { permission: {} as userPermission },
        children: [
            {
                title: 'Customer Order',
                link: UrlServices.PAGE_URL.ORDER.LIST.URL,
                icon: 'pi pi-shopping-bag',
                data: { permission: UrlServices.PAGE_URL.ORDER.LIST.permission },
            },
            // {
            //     title: 'Vendor Order',
            //     link: UrlServices.PAGE_URL.ORDER.VENDOR_ORDER_LIST.URL,
            //     icon: 'pi pi-shopping-cart',
            //     data: { permission: UrlServices.PAGE_URL.ORDER.VENDOR_ORDER_LIST.permission },
            // },
            {
                title: 'Payments',
                link: UrlServices.PAGE_URL.ORDER.ORDER_PAYMENT.URL,
                icon: 'pi pi-money-bill',
                data: { permission: UrlServices.PAGE_URL.ORDER.ORDER_PAYMENT.permission },
            },
        ]
    },
    {
        title: 'Report',
        icon: 'pi pi-chart-line',
        data: { permission: {} as userPermission },
        children: [
            {
                title: 'Customer Report',
                icon: 'pi pi-users',
                link: UrlServices.PAGE_URL.REPORT.CUSTOMER_FLAT_REPORT.URL,
                data: { permission: UrlServices.PAGE_URL.REPORT.CUSTOMER_FLAT_REPORT.permission },
                queryParams: { id: 'customer' }
            },
            {
                title: 'Order Report',
                icon: 'pi pi-shopping-cart',
                link: UrlServices.PAGE_URL.REPORT.ORDER_FLAT_REPORT.URL,
                data: { permission: UrlServices.PAGE_URL.REPORT.ORDER_FLAT_REPORT.permission },
                queryParams: { id: 'order' }
            },
            {
                title: 'Product Report',
                icon: 'pi pi-money-bill',
                link: UrlServices.PAGE_URL.REPORT.PAYMENT_REPORT.URL,
                data: { permission: UrlServices.PAGE_URL.REPORT.PAYMENT_REPORT.permission },
                queryParams: { id: 'order' }
            },
        ],
    },
];
