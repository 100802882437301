import { Component, OnInit } from '@angular/core';
import { AppConstant, REFERENCE_MODULE, RESPONSE_CODE } from 'src/app/helper/class/app-constant';
import { MENU_ITEMS } from 'src/app/helper/class/page-menu';
import { UrlServices } from 'src/app/helper/class/url-services';
import { cloneData, isEmptyObj } from 'src/app/helper/class/utilityHelper';
import { menu } from 'src/app/helper/interface/menu-interface';
import { User } from 'src/app/helper/interface/user';
import { AuthService } from 'src/app/helper/service/auth.service';
import { ModalService } from 'src/app/shared/service/modal.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { EditUserComponent } from 'src/app/modules/user/component/edit-user/edit-user.component';
import { FormControl } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ResponseData } from 'src/app/helper/interface/response';
import { MasterApiService } from 'src/app/modules/master/service/master-api.service';
import { OrderInfoComponent } from 'src/app/modules/order/components/order-info/order-info.component';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnInit {
  menu: any;
  isDev = !environment.production
  logo = AppConstant.LOGO_SRC;
  title = AppConstant.TITLE
  urlService = UrlServices.PAGE_URL;
  user: User = this.auth.currentUserValue;
  roleName = 'Staff';
  currentTab: any = '';
  isMenuOpen: boolean = false;
  searchControl: FormControl = new FormControl();
  searchSub: Subscription = {} as Subscription;
  resultData: any;
  showPanel: boolean=false;

  constructor(
    private auth: AuthService,
    private modalService: ModalService,
    private router: Router,
    private masterApi:MasterApiService
  ) { }

  ngOnInit(): void {
    this.setUserMenus();
    this.router.events.subscribe(value => {
      const p = this.router.url.toString().split('/');
      this.currentTab = p[0] ? p[0] : p[1] || '';
    });
    if (typeof this.searchSub.unsubscribe == "function") {
      this.searchSub?.unsubscribe();
    }
    this.searchSub = this.searchControl.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(v => {
      console.log('on search ', v)
      if (v) {
        this.searchData(v);
      } else {
        this.showPanel = false;
        this.resultData = [];
      }
    })
    this.stickyHeader()
    // this.roleName = this.user.role?.[0].roleName || 'Staff';

  }
  searchData(a){
    this.showPanel = true;

    this.masterApi.globalSearch(a).then((res: ResponseData | any) => {
      if (res.statusCode == RESPONSE_CODE.SUCCESS) {
        this.resultData = res.result;
        console.log(this.resultData,'resulte');
        
      }
    });
  }
  checkPermission(item: any) {
    if (isEmptyObj(item.data?.permission)) {
      return 'empty';
    }
    const mName = item.data?.permission.moduleName;
    const aName = item.data?.permission.actionName;
    const per = this.auth.checkPermission(mName, aName);
    const user = this.auth.currentUserValue;
    if (+user.ref_module_id == REFERENCE_MODULE.VENDOR && mName == 'VENDOR_ORDER' && aName == 'VIEW_ALL') {
      if (!per) {
        return 'my_order';
      }
    }
    // if (mName == 'MONTHLY_REPORT_STAFF' && aName != 'PROCESS') {
    //   if (!per) {
    //     return 'my_report';
    //   }
    // }
    return per;

  }
  searchOrder(a){
    const data = { id: a }
    this.modalService.openModal(OrderInfoComponent, data, 'modal-xl', 11).then((res) => {
      if (res) {
        // this.tableList?.reload();
      }
    })
    this.searchControl.setValue('')
  }


  stickyHeader() {
    const header = document.getElementById('header') as HTMLElement;
    const className = 'fixed-on-scroll';
    window.addEventListener('scroll', (ev) => {
      if (window.scrollY > 200) {
        header.classList.add(className)
      } else {
        header.classList.remove(className)
      }
    })
  }
  setUserMenus() {
    const filterRoles: any = [], menuItems: menu[] = [] as menu[];

    cloneData(MENU_ITEMS).forEach((item: any, index) => {
      const pM = this.checkPermission(item);
      const s = item.link?.split('/');
      item.urlParentPath = s?.[0] ? s?.[0] : s?.[1];
      //skip its false
      if (!pM) {
        return;
      }
      if (item.children?.length) {
        const chMenu: any = [];
        item.children.map((a: any) => {
          const chk = this.checkPermission(a);
          if (chk || chk == 'my_report' && chk != 'empty') {
            // if (chk === 'my_report') {
            if (typeof chk == 'string') {
              a.queryParams = a.queryParams ? a.queryParams : { id: chk }
            }
            // }
            //if menu item didnt have link take module name from children link to hightlight current tab
            if (!item.link) {
              const ul = a.link?.split('/');
              item.urlParentPath = ul?.[0] ? ul?.[0] : ul?.[1];
            }
            chMenu.push(a)
          }
        });
        if (!chMenu.length) {
          return;
        }
        item.children = chMenu;
      }
      menuItems.push(item);
    });
    this.menu = menuItems;
    console.log('menu items ', this.menu);
  }

  openChangePassword() {
    this.modalService.openModal(ChangePasswordComponent, {}, 'modal-md').then(res => {
      if (res) {
        this.auth.logout();
      }
    })
  }

  openMyProfile() {
    const data = { type: 'MY_PROFILE' };
    this.modalService.openModal(EditUserComponent, data, 'modal-xl').then(res => {
      if (res) {
        this.auth.logout();
      }
    })
  }

  logout() {
    this.auth.logout();
  }
}
