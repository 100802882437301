<div id="header" class="horizontal-menu">
    <nav class="navbar top-navbar col-lg-12 col-12 p-0">
        <div class="container-fluid">
            <div class="navbar-menu-wrapper d-flex align-items-center justify-content-between">
                <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <a class="navbar-brand brand-logo" href="#"><img [src]="logo" alt="logo" /></a>
                    <a class="navbar-brand brand-logo-mini" href="#">
                        <img alt="logo" /></a>
                </div>
                <ul class="justify-content-center navbar-collapse navbar-nav">
                    <span class="p-input-icon-left w-50">

                        <i class="pi pi-search"></i>
                        <input type="text" [formControl]="searchControl" placeholder="Search..." pInputText
                            class="form-control" style="padding-left: 2.5rem;" />
                        <p-overlay #opSearch [(visible)]="showPanel"
                            [responsive]="{ breakpoint: '640px', direction: 'bottom', contentStyleClass: 'h-20rem' }"
                            contentStyleClass=" surface-overlay shadow-2 border-round w-100">
                            <div class="dropdownInput shadow-lg p-3 mb-5 bg-white rounded">
                                <div class="dropdown-result">
                                    <div class="table-responsive ">
                                        <table class="table table-hover spacing8">
                                            <tbody>
                                                <tr *ngFor="let a of resultData" class="cursor-pointer w-100" (click)="searchOrder(a.id)">

                                                    <td>{{a?.order_id }}</td>
                                                    <td>{{a?.customer_name }}</td>
                                                    <td>{{a?.eventName }}</td>
                                                    <td>{{a?.total_amount }}</td>
                                                    <td>{{a?.created_at|dateFormat }}</td>
                                                </tr>
                                              
                                                <tr *ngIf="resultData?.length==0">
                                                    <td colspan="4">
                                                        <h5>No Matched Data</h5>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </p-overlay>

                    </span>


                </ul>

                <span class="d-flex">
                    <ul class="navbar-nav navbar-nav-right" style="cursor: pointer;">
                        <li class="nav-item nav-profile dropdown">
                            <a clickOutside (clickOutside)="isMenuOpen=false" (click)="isMenuOpen = !isMenuOpen"
                                class="nav-link dropdown-toggle">
                                <span class="nav-profile-name">
                                    {{(user.fname + ' ' +( user.lname || '') |titlecase)}}</span>
                                <img imageLoad [src]="user.imageUrl" alt="profile" />
                            </a>
                            <div [ngClass]="{'d-block':isMenuOpen}"
                                class="dropdown-menu dropdown-menu-right navbar-dropdown"
                                aria-labelledby="profileDropdown">
                                <a class="dropdown-item" (click)="openMyProfile()">
                                    <i class="pi pi-user text-primary"></i>
                                    My Profile
                                </a>
                                <a class="dropdown-item" (click)="openChangePassword()">
                                    <i class="pi pi-key text-primary"></i>
                                    Change Password
                                </a>
                                <a (click)="logout()" class="dropdown-item">
                                    <i class="pi pi-sign-out text-primary"></i>
                                    Logout
                                </a>
                            </div>
                        </li>
                    </ul>
                    <!-- <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                        data-toggle="horizontal-menu-toggle">
                        <span class="pi pi-bars"></span>
                    </button> -->
                </span>
            </div>
        </div>
    </nav>

    <!-- Nav Bar -->

    <nav class="bottom-navbar">
        <div class="container" style="max-width: 1160px !important;">
            <ul class="nav page-navigation">
                @for (m of menu; track m) {
                <li class="nav-item" [ngClass]="{'active': currentTab == m?.urlParentPath}">
                    <a class="nav-link" [routerLink]="m?.link ? ['/'+m?.link]:null" [attr.tittle]="m?.title"
                        [queryParams]="m?.queryParams"><i [attr.class]="m?.icon"></i>
                        <span class="menu-title mt-1">{{m?.title}}</span>
                        @if (m?.children) {
                        <i class="menu-arrow d-block"></i>
                        }
                    </a>
                    @if (m?.children) {
                    <div class="submenu">
                        @for ( ch of m?.children ; track ch) {
                        <ul>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/'+ch?.link]" [attr.tittle]="ch?.title"
                                    [queryParams]="ch?.queryParams">
                                    <i [attr.class]="ch?.icon"></i>
                                    {{ch?.title}}
                                </a>
                            </li>

                        </ul>
                        }
                    </div>
                    }
                </li>
                }
            </ul>
        </div>
    </nav>
</div>