<!-- <div class="auth-main particles_js" [style]="theme">
    <div class="auth_div vivify popIn">
        <div class="auth_brand">
            <a class="navbar-brand" href="javascript:void(0);"><img [src]="logo" width="30" height="30"
                    class="d-inline-block align-top me-2" alt="">{{title}}</a>
        </div>
        <div class="card">
            <div class="body" style="background-color:#f3f3f3">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div id="particles-js"></div>
</div> -->
<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="main-panel justify-content-center">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>