<p-toast (click)="onclick()" [baseZIndex]="5000" position="top-center" closable="false" [preventOpenDuplicates]="true"
  [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>
@if (loading) {
<div class="page-loader-wrapper">
  <div class="loader">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
    <div class="bar4"></div>
    <div class="bar5"></div>
  </div>
</div>
}
<router-outlet></router-outlet>